import { Link } from "react-router-dom"
import { motion } from "framer-motion"

const About = () => {
  return (
    <motion.div 
    className="page-container"
    initial={{
      opacity: 0
    }}
    animate={{
      opacity: 1,
    }}
    >
      <motion.div
        className="about"
          initial={{
          opactity: 0,
        }}
        animate={{
          opactiy: 1,
        }}
        exit={{
          opacity: 0,
          x: window.innerWidth,
        }}
      >
        <motion.div
          className="pop"
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
        >
          <a href="http://linkedin.com/in/andrewwallacekeir">
            <img src="/images/AK.png" alt="" />
          </a>
        </motion.div>
        <motion.div
          className="about1"
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
            transition: { delay: 1, duration: 1 },
          }}
        >
          <br />
           Hi! I'm AK. 
          <br />
          <br />
          I am excited by technology that re-empowers the individual;
          <br />
          and inspired by the creativity nested within technology and the impact it can have on how our lives.
          <br />
        </motion.div>
       
        <motion.button
          className="thruButton"
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
            transition: { delay: 1, duration: 1 },
          }}
          whileHover={{
            scale: 1.2,
            boxShadow: "0px 0px 8px rgb(255, 255, 255)",
          }}
        >
          <Link to="/proof-of-work">Portfolio</Link>
        </motion.button>
      </motion.div>
    </motion.div>
  )
}

export default About
